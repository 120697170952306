import "./App.css";
import Router from "./Router";
import "./styles/buttonStyles.css";
import "./styles/carousel.css";

function App() {
  return (
    <div className="App ">
      <>
        <Router />
      </>
    </div>
  );
}

export default App;
