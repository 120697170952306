import React from "react";
import "../styles/Header.css";

export default function Header(props) {
  const { title, subtitle, description, imageUrl, scrollToComponent } =
    props;

  const headerBg = {
    backgroundImage: `radial-gradient(
      ellipse at center,
      rgba(17, 4, 4, 0.38) 0%,
      rgba(0, 0, 0, 0.6) 100%),
      url(${imageUrl})`,
  };

  return (
    <header style={{ ...headerBg }} className="masthead px-2 px-lg-0">
      <div className="container">
        <div className="row align-items-center">
          <h5
            style={{
              paddingTop: "0.9rem",
              paddingBottom: "1.9rem",
              color: "white",
              fontWeight: "bold",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)",
            }}
          >
            {title}
          </h5>
          <h5
            style={{
              paddingBottom: "1.9rem",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.4)",
            }}
            className="col-lg-8 display-5 fw-bolder"
          >
            <span>{subtitle}</span>
          </h5>
          <h5
            style={{
              paddingBottom: "2rem",
              fontWeight: "normal",
              lineHeight: "2rem",
              color: "#fff",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)",
            }}
            className="col-lg-6"
          >
            {description}
          </h5>
          <div>
            <button
              id="headerButton"
              onClick={() => scrollToComponent("requestDemo")}
            >
              Contactez-nous
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
