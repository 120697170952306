import React from "react";
import Forms from "./utils/Forms";
import Map from "./utils/Map";

const RequestDemo = () => {
  return (
    <div>
      <section id="contactUs">
        <div className="container pb-4">
          <div className="row">
            <div className="col-md-6 pb-3">
              <Map />
            </div>
            <div className="col-md-6">
              <Forms />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RequestDemo;
