import React from "react";

const Footer = () => {
  return (
    <footer
      className="pb-2 text-white px-2 px-lg-0"
      style={{ backgroundColor: "#3a2513" }}
    >
      <div className="container pt-5">
        <div className="row">
          <div className="col-lg-6 order-lg-1">
            <div className="col-lg-10">
              <div className=" mb-5">
                <p className="mb-2">
                  <b>Téléphone :</b> 05222-86057
                </p>
                <p className="mb-2 fw-bold">Horaires d'ouverture :</p>
                <p className="mb-2">Du lundi au vendredi, de 09:00 à 17:30 </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-lg-2">
            <div className=" mb-6">
              <h6 className="fw-bolder">Maître ABDELAZIZ ZAHIDI</h6>
              <p>
                Espace ma santé, 40 ANGLE BOULEVARD ANOUAL EXTENSION ET BD BIR
                ANZARAN, Immeuble
              </p>
              <p>Casablanca, Maroc</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
