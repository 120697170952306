import React, { useState } from "react";

export default function Forms() {
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    tel: "",
    message: "",
  });

  const [requiredFieldsError, setRequiredFieldsError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    const { email, tel } = formData;
    if (email && !/\S+@\S+\.\S+/.test(email))
      errors.email = "L'email est invalide.";

    if (tel && !/^\d{10}$/.test(tel))
      errors.tel = "Téléphone doit contenir 10 chiffres.";

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      console.log("Validation errors:", errors);
      return;
    }

    const requiredFields = ["nom", "prenom", "email", "tel"];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    setRequiredFieldsError(missingFields.length > 0);

    if (missingFields.length > 0) {
      console.log("Missing required fields:", missingFields);
      return;
    }

    try {
      console.log(formData);
      const response = await fetch("http://localhost:3001/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      console.log("Response from server:", result);

      if (response.ok) {
        console.log("Formulaire soumis avec succès");
        setSuccessMessage(
          "Votre message a été envoyé avec succès. Nous vous contacterons prochainement."
        );
        setFormData({
          nom: "",
          prenom: "",
          email: "",
          tel: "",
          message: "",
        });
        setRequiredFieldsError(false);
      } else if (response.status === 500) {
        setSuccessMessage(
          "Votre message a été envoyé avec succès. Nous vous contacterons prochainement."
        );
        console.error("Échec de la soumission du formulaire:", result.error);
      } else {
        console.error("Erreur lors de la soumission:", result.error);
      }
    } catch (error) {
      console.error("Erreur:", error);
    }
  };

  return (
    <div className="container">
      <h6 className="fw-bold">
        Prenez le Premier Pas Vers une Assistance Juridique de Qualité
      </h6>
      <form onSubmit={handleSubmit}>
        <div className="form-group row">
          <div className="col-6 pb-3">
            <label htmlFor="nom" className="col-form-label">
              Nom*
            </label>
            <input
              type="text"
              className="form-control"
              id="nom"
              name="nom"
              value={formData.nom}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6 pb-3">
            <label htmlFor="prenom" className="col-form-label">
              Prénom*
            </label>
            <input
              type="text"
              className="form-control"
              id="prenom"
              name="prenom"
              value={formData.prenom}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-6 pb-3">
            <label htmlFor="email" className="col-form-label">
              Email*
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            {validationErrors.email && (
              <div className="text-danger pt-2">{validationErrors.email}</div>
            )}
          </div>
          <div className="col-6 pb-3">
            <label htmlFor="tel" className="col-form-label">
              Téléphone*
            </label>
            <input
              type="tel"
              className="form-control"
              id="tel"
              name="tel"
              value={formData.tel}
              onChange={handleInputChange}
            />
            {validationErrors.tel && (
              <div className="text-danger pt-2">{validationErrors.tel}</div>
            )}
          </div>
        </div>

        <div className="form-group pb-3 col-12">
          <label htmlFor="message" className="col-form-label">
            Message
          </label>
          <textarea
            className="form-control"
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            placeholder="Votre message ici..."
          />
          {requiredFieldsError && (
            <div className="text-danger pt-2">
              Veuillez remplir tous les champs obligatoires *
            </div>
          )}
        </div>

        <button type="submit" className="d-block mx-auto">
          Envoyer
        </button>

        {successMessage && (
          <div className="text-success pt-3">{successMessage}</div>
        )}
      </form>
    </div>
  );
}
