import React from "react";
import { Navbar } from "react-bootstrap";
import NavItems from "./NavItems";

export default function NavBar({ scrollToComponent }) {
  return (
    <Navbar
      expand="lg"
      className={`h-wrapper navbar-expand-lg px-lg-5 fixed-top activenavbar`}
    >
      <NavItems scrollToComponent={scrollToComponent} />
    </Navbar>
  );
}
