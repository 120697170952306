import React, { useEffect, useRef } from "react";
import Services from "../components/Services";
import Header from "../components/Header";
import Footer from "../components/utils/Footer";
import NavBar from "../components/navigations/NavBar";
import AboutUs from "../components/AboutUs";
import RequestDemo from "../components/RequestDemo";

const Home = () => {
  const aboutUsRef = useRef(null);
  const servicesRef = useRef(null);
  const requestDemoRef = useRef(null);

  useEffect(() => {
    document.title = "ZAHIDI NOTAIRE | EXPERT NOTARIAL";
  }, []);

  const scrollToComponent = (component) => {
    const offset = -40;
    let element;

    if (component === "aboutUs") {
      element = aboutUsRef.current;
    } else if (component === "services") {
      element = servicesRef.current;
    } else if (component === "requestDemo") {
      element = requestDemoRef.current;
    }

    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition + offset,
        behavior: "smooth",
      });
    }
  };

  const aboutUs = [
    "Fondé sur les valeurs de transparence, d’intégrité et de professionnalisme, notre cabinet s'engage à vous offrir des services juridiques d'excellence.",
    "Grâce à notre expertise et à notre connaissance approfondie du droit marocain, notre mission est de garantir la sécurité juridique de vos projets tout en établissant une relation de confiance.",
    "Nous sommes à votre écoute pour vous conseiller et vous fournir des solutions sur mesure.",
  ];

  return (
    <div>
      <NavBar useDynamicNavState={true} scrollToComponent={scrollToComponent} />

      <Header
        title="NOTAIRE"
        subtitle="ZAHIDI ABDELAZIZ"
        description={`Bénéficiez d’une expertise juridique exceptionnelle, d’un service personnalisé haut de gamme et d’une confidentialité absolue pour répondre à toutes vos exigences légales.`}
        imageUrl="/assets/gallery/notary-bg.jpg"
        scrollToComponent={scrollToComponent}
      />

      <div ref={aboutUsRef}>
        <AboutUs
          data={{
            title: "À propos de nous",
            imgSrc: "/assets/3.jpg",
            content: { aboutUs },
            imageFirst: false,
          }}
        />
      </div>

      <div ref={servicesRef}>
        <Services
          title="Votre Partenaire Juridique de Confiance  "
          benefits={[
            {
              imageSrc: "/assets/icons/real-estate.png",
              description: "Droit Immobilier ",
              description2: "Achat, vente, hypothèques, donations.",
            },
            {
              imageSrc: "/assets/icons/business.png",
              description: "Droit des Affaires",
              description2:
                "Création d'entreprises et des contrats commerciaux.",
            },
            {
              imageSrc: "/assets/icons/law.png",
              description: "Conseils Juridiques",
              description2: "Consultations pour tous vos besoins juridiques.",
            },
          ]}
        />
      </div>
      <div ref={requestDemoRef} className="container">
        <div className=" py-3  text-center ">
          <h1 style={{ fontWeight: "bold" }}>Visitez-nous</h1>
        </div>
        <RequestDemo />
      </div>

      <Footer />
    </div>
  );
};

export default Home;
