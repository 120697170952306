import React from "react";

export default function AboutUs({ data }) {
  const { title, imgSrc, content, imageFirst, textcolor, bgColor } = data;

  return (
    <div style={{ backgroundColor: bgColor }} className="py-5">
      <div className="container py-lg-5">
        <div className="row align-items-center">
          <div className="text-center pb-3">
            <h1 style={{ fontWeight: "bold", color: "#3a2513" }}>{title}</h1>
          </div>
          {/* CONTENT */}
          <div
            className={`col-lg-6 col-12 order-lg-${
              imageFirst ? "2" : "1"
            } order-2 pb-sm-3`}
            style={{ color: textcolor ? textcolor : "black" }}
          >
            {content.aboutUs.map((item, index) => (
              <div className="pb-3 h5" key={index}>
                {item}
              </div>
            ))}
          </div>
          {/* IMAGE */}
          <div
            className={`col-12 col-lg-6 col-md-6 order-1 order-lg-${
              imageFirst ? "1" : "2"
            } } mb-lg-0 mb-sm-4 pb-lg-0 pb-3`}
          >
            <div className="img-fluid row justify-content-center align-items-center">
              <img src={imgSrc} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
