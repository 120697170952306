import React from "react";

const ServiceItem = ({ imageSrc, description, description2 }) => (
  <div className="col-lg-4 col-md-6 col-12 text-center pt-lg-5">
    <div className=" mt-5 mb-2">
      <img className="img-fluid" src={imageSrc} alt="Benefit" />
    </div>
    <div className="col-lg-12 col-12 text-center">
      <div className="row justify-content-center">
        <div className="col-lg-9 col-12 text-center">
          <p className="mb-0 fw-bold">{description}</p>
          <p className="mb-0">{description2}</p>
        </div>
      </div>
    </div>
  </div>
);

const Services = ({ title, benefits = [] }) => {
  return (
    <div style={{ backgroundColor: "#492e17" }} className="py-lg-5">
      <div  className="container text-white py-5 my-lg-5">
        <div className="text-center">
          <h1 className="fw-bolder">{title}</h1>
        </div>

        <div className="row">
          {benefits.map((benefit, index) => (
            <ServiceItem
              key={index}
              imageSrc={benefit.imageSrc}
              description={benefit.description}
              description2={benefit.description2}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
