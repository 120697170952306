import React from "react";
import { Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "../../styles/NavBar.css";

export default function NavItems({ scrollToComponent }) {
  return (
    <Container className="py-0">
      <Navbar.Brand className="d-flex align-items-center justify-content-between">
        <img
          src="../assets/logo.png"
          className="col-lg-4 col-md-4 col-6"
          alt="logo"
        />
        <Navbar.Toggle />
      </Navbar.Brand>

      <Navbar.Collapse>
        <Nav className="ms-auto dropdown-hover">
          <Nav.Link onClick={() => scrollToComponent("aboutUs")}>
            À propos
          </Nav.Link>
          <Nav.Link onClick={() => scrollToComponent("services")}>
            Services
          </Nav.Link>
          <Nav.Link onClick={() => scrollToComponent("requestDemo")}>
            Contact
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  );
}
