import React from "react";

const Map = () => {
  return (
    <section id="contactUs">
      <div className="container">
        <div className="row">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13297.407678251617!2d-7.6273977!3d33.5702109!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7d2a6574a5409%3A0x60e8e13bab1f13f!2sMa%C3%AEtre%20abdelaziz%20zahidi%20notaire%20%C3%A0%20Casablanca!5e0!3m2!1sfr!2sma!4v1725285714633!5m2!1sfr!2sma"
            className="card-img-top"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Map"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Map;
